import {
  decorateDtAndActionModifiers,
  ModifiersDecorator,
} from "@sutro/studio2-quarantine/lib/decorate-dt-and-action-modifiers";
import { useSaveState } from "@sutro/studio2-quarantine/lib/use-save-state";
import { ActionModifiers } from "@sutro/studio2-quarantine/types/action-modifiers";
import { DtModifiers } from "@sutro/studio2-quarantine/types/dt-modifiers";
import { OnChangeAppMeta } from "@sutro/studio2-quarantine/types/on-change-app-meta";
import { SwitchDefinitionParams } from "@sutro/studio2-quarantine/types/switch-definition-params";
import { UpdateDefinition } from "@sutro/studio2-quarantine/types/update-definition";
import { UpdateDefinitionInStateAfterValidating } from "@sutro/studio2-quarantine/types/update-definition-in-state-after-validating";
import { UpdateDefinitionWithFeatureTemplateStatePatch } from "@sutro/studio2-quarantine/types/update-definition-with-feature-template-state-patch";
import { UpdateDefinitionWithPatch } from "@sutro/studio2-quarantine/types/update-definition-with-patch";
import _debounce from "debounce";
import { Draft, produce } from "immer";
import posthogJs from "posthog-js";
import { toast } from "sonner";
import { StudioEventTypes } from "sutro-analytics";
import {
  $TSFixMe,
  $TSFixMeFunction,
  CURRENT_DEFINITION_VERSION,
  deepmergeAlt,
  Definition,
  GetBundleForStudioOptions,
  getRandom,
  isDtPrimitive,
  jsonCopy,
  KeyedUiConfigContainersForNodeChildren,
  UiConfigContainerForNodeAndDescendants,
} from "sutro-common";
import { defaultAppMeta } from "sutro-common/app-meta";
import { SerializedSClientScript } from "sutro-common/client-app/sclientscript";
import { SchemeConfigurationOptions } from "sutro-common/color-scheme";
import { UnknownObject } from "sutro-common/object-types";
import { primitiveDtsForInitializingDefinition } from "sutro-common/primitives-and-meta";
import type { SLangParseResult } from "sutro-common/slang/compilation-outcome";
import { AppPreviewDevices } from "sutro-common/studio-preview-comm-layer/types";
import {
  DefinitionValidation,
  SERVER_ERROR_TYPES,
} from "sutro-common/sutro-api/types";
import {
  Product,
  ProductId,
  ProductWithPublishedVersion,
} from "sutro-common/sutro-data-store-types";
import { SerializableGeneratedTheme } from "sutro-common/theming/generated-theme";
import { trace } from "sutro-common/tracing/trace";
import { assert } from "tsafe";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";

import {
  getDefinitionWithFeatureTemplatePatchApplied,
  getUpdatedDefinitionAfterTransaction,
} from "~/lib/definitions/definition-updaters";
import { getPatchForUpdatingDefinitionInStateAfterValidating } from "~/lib/definitions/definition-updaters/get-patch-for-updating-definition-in-state-after-validating";
import { getUpdatedAndValidatedDefinition } from "~/lib/definitions/definition-validation";
import { HttpResponse } from "~/lib/sutro-api/Api";
import { SutroApi } from "~/lib/sutro-api/index";
import { StudioHttpError } from "~/lib/sutro-api/StudioHttpError";

import { getNewDefinition } from "../definitions/new-definition/get-new-definition";
import getBundleForStudio from "../get-bundle-for-studio";
import { StudioError } from "../studio-error";

type RefreshBundleFunction = (
  definition: Definition,
  options: GetBundleForStudioOptions,
  onError?: (error: Error) => void
) => void;

export interface Studio {
  dtModifiers: DtModifiers;
  actionModifiers: ActionModifiers;
  slangParseResult: SLangParseResult | null;
  /**
   * The `workingDefinition` is what's updated in response to all Studio activity. It may or may not be
   * valid at any given point in time, but should generally be assumed to be invalid
   */
  workingDefinition: Definition;
  /**
   * The `validDefinition` is only every updated when the `workingDefinition` is valid. It is set to `null` to begin with.
   * When not null, it's what is used for saving and publishing
   */
  definitionValidation?: DefinitionValidation | undefined;
  validDefinition: Definition | null;
  postPluginDefinition: Definition | undefined | null;
  product: ProductWithPublishedVersion | undefined;
  refreshProduct: () => Promise<void>;
  updateDefinitionWithPatch: UpdateDefinitionWithPatch;
  onChangeAppMeta: OnChangeAppMeta;
  updateDefinitionWithFeatureTemplateStatePatch: UpdateDefinitionWithFeatureTemplateStatePatch;
  updateDefinitionInStateAfterValidating: UpdateDefinitionInStateAfterValidating;
  updateDefinition: UpdateDefinition;
  generatedTheme: {
    darkTheme: SerializableGeneratedTheme;
    lightTheme: SerializableGeneratedTheme;
  } | null;
  setGeneratedTheme: (generatedTheme: {
    darkTheme: SerializableGeneratedTheme;
    lightTheme: SerializableGeneratedTheme;
  }) => void;
  sClientScript: SerializedSClientScript | null;
  setSClientScript: $TSFixMeFunction;
  switchDefinition: (params: SwitchDefinitionParams) => void;
  // isDisplaySmall: boolean;
  skipLogIn: boolean;
  setSkipLogIn: (skip: boolean) => void;
  updatePageContentsConfig: (state: {
    path: string[];
    newObjToMerge: Record<string, $TSFixMe>;
  }) => void;
  studioDefinitionLoading: boolean;
  setStudioDefinitionLoading: (newState: boolean) => void;
  studioDefinitionError: StudioHttpError | null;
  fetchNewBundleForStudioAndUpdateState: RefreshBundleFunction;
  sessionId: string;
  previewSchemeSetting: SchemeConfigurationOptions;
  validateWorkingDefinition: (
    causedByUserAction?: boolean,
    onError?: (e: Error) => void
  ) => void;
  setWorkingDefinitionAndValidate: (newWorkingDefinition: Definition) => void;
  executeSave: (options?: {
    definition: Definition;
    productId?: ProductId;
  }) => Promise<ProductId | undefined>;
  inSupportMode: boolean;
  appPreviewDevice: AppPreviewDevices;
  setAppPreviewDevice: (appPreviewDevice: AppPreviewDevices) => void;
}

export const useStudio = create<Studio>()((set, get) => {
  /*
   * Shorthand function for setting the workingDefinition
   */
  const setWorkingDefinition = (newWorkingDefinition: Definition) =>
    set(() => ({ workingDefinition: newWorkingDefinition }));
  /*
   * Shorthand function for updating the workingDefinition
   */
  const updateWorkingDefinition = (
    updateFunction: (current: Definition) => { workingDefinition: Definition }
  ) => set((state) => updateFunction(state.workingDefinition));
  /*
   * Shorthand function for setting the validDefinition
   */
  const setValidDefinition = (newValidDefinition: Definition) =>
    set(() => ({ validDefinition: newValidDefinition }));

  const decorateCallbackForUpdatingDefinition: ModifiersDecorator =
    <A extends unknown[], R extends object | void>(
      fn: (draft: Draft<Definition>, ...args: A) => R
    ) =>
    (...params: A) => {
      const { workingDefinition } = get();
      let copiedResult;

      const newDefinition = produce(
        { ...workingDefinition },
        (draftDefinition) => {
          posthogJs.capture(StudioEventTypes.DEFINITION_MODIFIED, {
            modifier: fn.name,
            params,
            productId: get().product?.id,
          });
          const rawResult = fn(draftDefinition, ...params);
          copiedResult =
            rawResult !== undefined ? jsonCopy(rawResult) : undefined;
        }
      );

      setWorkingDefinitionAndValidate(newDefinition);

      return copiedResult as R;
    };

  const validateWorkingDefinition = (
    causedByUserAction?: boolean,
    onError?: (e: Error) => void
  ) => {
    const { workingDefinition } = get();
    const { updatedDefinition, validatedDefinition, error, changeTracker } =
      getUpdatedAndValidatedDefinition({
        definition: workingDefinition,
      });

    if (changeTracker.logSize > 0) {
      changeTracker.showChanges("VALIDATION MADE CHANGES");
    }

    if (error !== undefined) {
      console.error("Error validating definition", error);
      return;
    }
    updateDefinitionInStateAfterValidating({
      newDefinition: updatedDefinition,
      causedByUserAction,
    });
    setValidDefinition(validatedDefinition);

    if (typeof window !== "undefined") {
      if (causedByUserAction) {
        const { skipLogIn, sessionId, product } = get();
        // This should always be true
        if (product) {
          fetchNewBundleForStudioAndUpdateState(
            validatedDefinition,
            {
              productId: product?.id,
              skipLogIn,
              previewUser: "Admins",
              sessionId,
            },
            onError
          );
        } else {
          console.error(
            "Product ID undefined when fetching a new bundle after validation."
          );
        }
      }
    }
  };

  const setWorkingDefinitionAndValidate = (
    newWorkingDefinition: Definition,
    causedByUserAction = true
  ) => {
    const oldWorkingDefinition = get().workingDefinition;

    const errorHandler = () => {
      toast.error(
        "There was a problem with the previous change, so we're rolling thing back"
      );
      setWorkingDefinition(oldWorkingDefinition);
      validateWorkingDefinition(causedByUserAction, (e) => {
        toast.error(
          `Rolling back did not work, so please contact support and let them know that you got this error: ${e.message}`
        );
      });
    };
    try {
      setWorkingDefinition(newWorkingDefinition);
      validateWorkingDefinition(causedByUserAction, errorHandler);
    } catch (e) {
      errorHandler();
    }
  };

  const updateDefinitionWithPatch: UpdateDefinitionWithPatch = (
    patch,
    causedByUserAction
  ) => {
    setWorkingDefinitionAndValidate(
      {
        ...get().workingDefinition,
        ...patch,
      },
      causedByUserAction
    );
  };

  const onChangeAppMeta: OnChangeAppMeta = (
    newMetaPatch,
    { switchingFullApp, causedByUserAction } = {}
  ) => {
    causedByUserAction = causedByUserAction ?? true;

    if (causedByUserAction) {
      // todo: capture analytics event SUT-2716
      // captureAnalyticsEvent(ANALYTICS_EVENT.UPDATE_DEFINITION, {
      //   type: "onChangeAppMeta",
      //   causedByUserAction,
      // });
    }

    if (causedByUserAction === true && switchingFullApp !== true) {
      // Note: can call onChangeAppMeta({}) if dts changed and just need to update this meta. This is pretty ugly but works for now.
      newMetaPatch[
        get().inSupportMode ? "lastEditedByAdmin" : "lastEditedByCreator"
      ] = Date.now();
    }

    if (switchingFullApp) {
      const newAppMeta = deepmergeAlt(
        { ...defaultAppMeta },
        {
          ...newMetaPatch,
          key: getRandom(),
        }
      );
      setWorkingDefinitionAndValidate(
        {
          ...get().workingDefinition,
          appMeta: newAppMeta,
        },
        causedByUserAction
      );
    } else {
      // Must do this instead of using appMeta, because reference to onChangeAppMeta passed into state machine may have stale references
      setWorkingDefinitionAndValidate(
        {
          ...get().workingDefinition,
          appMeta: deepmergeAlt(get().workingDefinition.appMeta, newMetaPatch),
        },
        causedByUserAction
      );
    }
  };

  const updateDefinitionWithFeatureTemplateStatePatch: UpdateDefinitionWithFeatureTemplateStatePatch =
    ({
      newFeatureTemplateState,
      newFrameworkMetaPatch,
      featureTemplateKey,
    }) => {
      const { workingDefinition } = get();

      const newDefinition = getDefinitionWithFeatureTemplatePatchApplied({
        prevDefinition: workingDefinition,
        newFeatureTemplateState,
        newFrameworkMetaPatch,
        featureTemplateKey,
      });

      setWorkingDefinitionAndValidate(newDefinition);
    };

  const updateDefinitionInStateAfterValidating: UpdateDefinitionInStateAfterValidating =
    ({ newDefinition, isInitializing = false, causedByUserAction = false }) => {
      const setUnsavedChanges = useSaveState.getState().setUnsavedChanges;

      let patch: ReturnType<
        typeof getPatchForUpdatingDefinitionInStateAfterValidating
      > = {
        patchForUpdatingDefinition: {},
        patchForValidDefinition: {},
      };
      try {
        patch = getPatchForUpdatingDefinitionInStateAfterValidating({
          newDefinition,
          isInitializing,
        });
      } catch (error) {
        // todo: capture analytics event SUT-2716
        // captureAnalyticsEvent(ANALYTICS_EVENT.VALIDATION_ERROR, {
        //   type: "updateDefinitionInStateAfterValidating",
        //   error,
        //   definition: newDefinition,
        //   isInitializing,
        //   causedByUserAction,
        // });
        return;
      }

      if (causedByUserAction) {
        setUnsavedChanges(true);
        // todo: capture analytics event SUT-2716
        // captureAnalyticsEvent(ANALYTICS_EVENT.UPDATE_DEFINITION, {
        //   type: "updateDefinitionInStateAfterValidating",
        //   causedByUserAction,
        // });
      }
      updateWorkingDefinition((currentWorkingDefinition) => ({
        workingDefinition: {
          ...currentWorkingDefinition,
          ...patch?.patchForUpdatingDefinition,
        },
      }));
    };

  // See readme.md in the definition updaters folder for more info about updateDefinition
  const updateDefinition: UpdateDefinition = (
    fnThatTakesUpdaters
  ): Definition => {
    const { workingDefinition } = get();

    const updatedDefinition = getUpdatedDefinitionAfterTransaction({
      definition: {
        ...workingDefinition,
        version: CURRENT_DEFINITION_VERSION,
      },
      fnThatTakesUpdaters,
    });

    updateDefinitionInStateAfterValidating({
      newDefinition: updatedDefinition,
      isInitializing: false,
    });

    return updatedDefinition;
  };

  const setSClientScript = (sClientScript: SerializedSClientScript | null) => {
    set(() => ({
      sClientScript,
    }));
  };

  const switchDefinition = ({
    definition,
    causedByUserAction,
    product,
  }: SwitchDefinitionParams) => {
    // This needs to go first so that everything can reference it
    set({ product });
    // The primitives change over time and were (maybe unnecessarily) stored in the backend per-definition so may be stale, so we here remove all the saved primitives and add the latest ones
    const newDtsWithPrimitivesRemoved = definition.dataTypes.filter(
      (dt) => isDtPrimitive(dt) === false
    );
    const newDtsWithBuiltInPrimitives = [
      ...primitiveDtsForInitializingDefinition,
      ...newDtsWithPrimitivesRemoved,
    ];

    updateDefinitionInStateAfterValidating({
      newDefinition: {
        ...definition,
        dataTypes: newDtsWithBuiltInPrimitives,
        actions: definition.actions ?? [],
        uiAnnotations: definition.uiAnnotations ?? [],
      },
      isInitializing: true,
      causedByUserAction,
    });
    onChangeAppMeta(definition.appMeta, {
      switchingFullApp: true,
      causedByUserAction,
    });
    // Honestly, this makes no sense to me, since `definition` is typed as Definition
    // Leaving as is for now
    updateDefinitionWithPatch({
      pageContentsConfig: definition.pageContentsConfig,
    });
  };

  const setSkipLogIn = (skipLogIn: boolean) => {
    set({ skipLogIn });
  };

  const updatePageContentsConfig = ({
    path,
    newObjToMerge,
  }: {
    path: string[];
    newObjToMerge: UnknownObject;
  }) => {
    const { workingDefinition } = get();

    // todo: capture analytics event SUT-2716
    // captureAnalyticsEvent(ANALYTICS_EVENT.UPDATE_DEFINITION, {
    //   type: "updatePageContentsConfig",
    // });

    updateDefinitionWithPatch({
      pageContentsConfig: produce(
        workingDefinition.pageContentsConfig,
        (draftPageContentsConfig) => {
          let objToUpdate:
            | KeyedUiConfigContainersForNodeChildren
            | UiConfigContainerForNodeAndDescendants = draftPageContentsConfig;
          for (const pathElem of path.slice(0, -1)) {
            //@ts-expect-error TS7053
            objToUpdate = objToUpdate[pathElem].contains;
          }
          //@ts-expect-error TS7053
          objToUpdate = objToUpdate[path[path.length - 1]];
          objToUpdate.configForCurrentNode = {
            ...objToUpdate.configForCurrentNode,
            ...newObjToMerge,
          };
        }
      ),
    });
  };

  const setStudioDefinitionLoading = (studioDefinitionLoading: boolean) => {
    set({ studioDefinitionLoading });
  };

  const _refreshBundleForStudioDebounced = _debounce(
    (
      fetchFunction: RefreshBundleFunction,
      ...fetchArgs: Parameters<RefreshBundleFunction>
    ) => {
      fetchFunction(...fetchArgs);
    },
    1000
  );

  const fetchNewBundleForStudioAndUpdateState: RefreshBundleFunction = (
    ...args: Parameters<RefreshBundleFunction>
  ) => {
    const _fetchFunction = async (
      ..._args: Parameters<RefreshBundleFunction>
    ) => {
      const [definition, options, onError] = _args;
      set({ slangParseResult: null });

      await trace(
        "sutro-studio",
        "fetchNewBundleForStudioAndUpdateState",
        async ({ addSpanError, addSpanAttribute }) => {
          set({ definitionValidation: undefined, studioDefinitionError: null });
          const { bundleForStudio, error: bundleError } =
            await getBundleForStudio({
              definition,
              options,
            });

          if (bundleError) {
            addSpanError(
              new StudioError("BundleForStudioError", {
                cause: bundleError.cause,
                context: {
                  message: bundleError.message,
                },
              })
            );
            addSpanAttribute("exception.escaped", false);
            set({ studioDefinitionError: bundleError });

            const serverError = bundleError.context?.serverError;
            const useDefault =
              serverError?.useDefault || serverError?.message === undefined;
            const message = useDefault
              ? "Unfortunately, we had an issue building your app."
              : serverError.message;

            if (onError) {
              onError(bundleError);
            }

            if (serverError?.type === SERVER_ERROR_TYPES.BadDefinition) {
              const definitionValidation: DefinitionValidation =
                serverError.additionalInfo;
              set({ definitionValidation });
            } else {
              toast.error(message);
            }
          } else {
            assert(bundleForStudio, "bundleForStudio should be defined");
            set({ slangParseResult: bundleForStudio.slangParseResult });
            set(
              // Freeze to ensure we don't accidentally mutate it downstream
              (state) => {
                const currentCount =
                  state.sClientScript?.studioPreviewsCount ?? 0;
                return {
                  sClientScript: {
                    ...bundleForStudio.sClientScript,
                    // Every time we set the interpreter bundle, we increment a count in it
                    studioPreviewsCount: isNaN(currentCount)
                      ? 0
                      : currentCount + 1,
                  },
                };
              }
            );

            if (bundleForStudio?.studioMetaUpdate?.notifs) {
              onChangeAppMeta(
                { notifs: bundleForStudio?.studioMetaUpdate?.notifs },
                {
                  causedByUserAction: false,
                }
              );
            }

            if (bundleForStudio?.studioMetaUpdate?.pageContentsConfig) {
              updateDefinitionWithPatch(
                {
                  pageContentsConfig:
                    bundleForStudio.studioMetaUpdate.pageContentsConfig,
                },
                false
              );
            }

            if (bundleForStudio?.studioMetaUpdate?.postPluginDefinition) {
              set({
                postPluginDefinition:
                  bundleForStudio.studioMetaUpdate.postPluginDefinition,
              });
            }
          }
          setStudioDefinitionLoading(false);
        }
      );
    };
    setStudioDefinitionLoading(true);
    _refreshBundleForStudioDebounced(_fetchFunction, ...args);
  };

  const { dtModifiers, actionModifiers } = decorateDtAndActionModifiers(
    decorateCallbackForUpdatingDefinition,
    updateDefinitionInStateAfterValidating
  );

  const setGeneratedTheme = (theme: $TSFixMe) => {
    set({ generatedTheme: theme });
  };

  const refreshProduct = async () => {
    const { product } = get();
    const result = await SutroApi.getApi()
      .authenticate()
      .get<Product>(`/products/${product?.id}`);

    if (result.isRight()) {
      set({ product: result.getRight() });
    } else {
      toast.error("Failed to refresh product");
    }
  };

  return {
    dtModifiers,
    actionModifiers,
    slangParseResult: null,
    workingDefinition: getNewDefinition(),
    validDefinition: null,
    postPluginDefinition: undefined,
    product: undefined,
    refreshProduct,
    onChangeAppMeta,
    updateDefinitionWithPatch,
    updateDefinitionWithFeatureTemplateStatePatch,
    updateDefinitionInStateAfterValidating,
    updateDefinition,
    generatedTheme: null,
    setGeneratedTheme,
    sClientScript: null,
    setSClientScript,
    switchDefinition,
    skipLogIn: true,
    setSkipLogIn,
    updatePageContentsConfig,
    studioDefinitionLoading: false,
    setStudioDefinitionLoading,
    studioDefinitionError: null,
    fetchNewBundleForStudioAndUpdateState,
    sessionId: uuidv4(),
    previewSchemeSetting: SchemeConfigurationOptions.AUTO,
    inSupportMode: false,
    appPreviewDevice: AppPreviewDevices.IPHONE_15_PRO,
    setAppPreviewDevice: (device) => {
      set({ appPreviewDevice: device });
    },
    executeSave: async (options?: {
      definition: Definition;
      productId?: ProductId;
    }) => {
      let validatedDefinition = get().validDefinition;

      if (options?.definition != undefined) {
        const { validatedDefinition: newDefinition, error } =
          getUpdatedAndValidatedDefinition({
            definition: options.definition,
          });
        validatedDefinition = newDefinition;
        if (error !== undefined) {
          throw new StudioError("Error validating definition to be saved", {
            context: { error },
          });
        }
      }

      const { setSaveInProgress, setUnsavedChanges } = useSaveState.getState();

      setSaveInProgress(true);
      try {
        let result: HttpResponse<unknown>;
        // const firstSave = false;
        let productId = options?.productId
          ? options?.productId
          : // If definition is set but productId is not, assume they intentionally want a new product.
            options?.definition != undefined
            ? undefined
            : get().product?.id;
        if (productId === undefined) {
          // firstSave = true;
          result = await SutroApi.getApi()
            .authenticate()
            .post(`/products`, {
              definition: JSON.stringify(validatedDefinition),
            });

          if (result.isLeft()) {
            throw result.getLeft();
          }

          result.ifRight((newProduct) => {
            productId = (newProduct as ProductWithPublishedVersion)?.id;
            onChangeAppMeta({
              id: productId,
            });
            set({ product: newProduct as ProductWithPublishedVersion });
          });
        } else {
          result = await SutroApi.getApi()
            .authenticate()
            .put(`/products/${productId}`, {
              definition: JSON.stringify(validatedDefinition),
            });
        }

        setSaveInProgress(false);
        if (result.isLeft()) {
          result.ifLeft(() => {
            toast.error(
              // TODO: There's no way to retry...
              "Something went wrong trying to save. Please try again later."
            );
          });
          setUnsavedChanges(true);
          return;
        } else {
          setUnsavedChanges(false);

          return productId;
        }
      } catch (e) {
        console.error(e);
        toast.error(
          "Something went wrong trying to save. Please try again later.",
          { description: e instanceof Error ? e.message : String(e) }
        );
        throw e;
      }
    },
    validateWorkingDefinition,
    setWorkingDefinitionAndValidate,
  };
});
